import React from 'react';
import { AgendaListItem } from '../../elements/agenda-list-item/agenda-list-item';

import css from './agenda-list.module.scss';

export const AgendaList: React.FC = () => {
    return (
        <ul className={css.root}>
            <AgendaListItem
                eventName="Mellow Monday"
                eventInfo="every monday from 19:00 till 22:00 recharge after your weekend"
                eventLink="/agenda/mellow-monday"
            />
            <AgendaListItem
                eventName="No Towel Tuesday"
                eventInfo="every tuesday evening from 18:00 till 02:00: no towels allowed (totally naked!)"
                eventLink="/agenda/no-towel-tuesday"
            />
            <AgendaListItem
                eventName="Queer Night"
                eventInfo="every wednesday"
                eventLink="/agenda/queer-night"
            />
            <AgendaListItem
                eventName="The Night Is Young"
                eventInfo="every thursday"
                eventExtra="guys 18 - 26 yo entrance € 10 from 18:00 (other customers regular prices)"
                eventLink="/agenda/the-night-is-young"
            />
            <AgendaListItem
                eventName="Bears (working up a sweat)"
                eventInfo="every last saturday"
                eventExtra="the bears, daddies, chasers, chubs and their admirers meet at nz"
                eventLink="/agenda/bears-at-nz"
            />
        </ul>
    );
};
