import React from 'react';
import { Helmet } from 'react-helmet';
import { PictureBlock } from '../../common/elements/picture-block/picture-block';
import { ContentBlock } from '../../common/elements/content-block/content-block';
import { AgendaList } from '../../common/features/agenda-list/agenda-list';
import { Title } from '../../common/elements/title/title';
import { ScrollToTop } from '../../common/scripts/scroll-to-top';

import css from './agenda.module.scss';

export const Agenda = () => {
    return (
        <ScrollToTop>
            <Helmet>
                <title>Agenda | Nieuwezijds Gay Sauna Amsterdam</title>
                <meta
                    name="description"
                    content="The agenda of the Nieuwezijds Gay Sauna in Amsterdam."
                />
            </Helmet>
            <section className={css.root}>
                <PictureBlock />
                <ContentBlock position="2">
                    <Title as="h1" text="Agenda" className={css.note} />
                    <AgendaList />
                </ContentBlock>
            </section>
        </ScrollToTop>
    );
};
