import React from 'react';
import { Helmet } from 'react-helmet';
import { Link } from 'react-router-dom';
import { ScrollToTop } from '../../common/scripts/scroll-to-top';
import { PictureBlock } from '../../common/elements/picture-block/picture-block';
import { ContentBlock } from '../../common/elements/content-block/content-block';
import { Paragraph } from '../../common/elements/paragraph/paragraph';
import { HomeAgenda } from '../../common/features/home-agenda/home-agenda';
import css from './home.module.scss';
import { ExternalLink } from '../../common/elements/external-link/external-link';

export const Home = () => {
    return (
        <ScrollToTop>
            <Helmet>
                <title>Nieuwezijds Gay Sauna Amsterdam</title>
                <meta
                    name="description"
                    content="The website of the Nieuwezijds Gay Sauna at the Nieuwezijds Armsteeg 95 in Amsterdam."
                />
            </Helmet>
            <section className={css.root}>
                <HomeAgenda />
                <PictureBlock isHome />
                <ContentBlock isHome position="2">
                    <Paragraph isHome promo>
                        Welcome to Sauna NZ
                    </Paragraph>

                    <Paragraph isHome>
                        Amsterdam’s award winning sauna exclusively for gay men!
                    </Paragraph>

                    <Paragraph isHome>
                        Check out <Link to="/agenda">our agenda</Link> for our
                        exciting weekly events.
                    </Paragraph>

                    <Paragraph isHome>Hope to see you soon!</Paragraph>

                    <Paragraph isHome>The NZ Team ❤️🌈</Paragraph>

                    <Paragraph isHome promo className={css.note}>
                        PS: Have you visited our partner company{' '}
                        <ExternalLink
                            href="https://www.freewillie.nl/"
                            text="Free Willie"
                        />{' '}
                        yet? Pick up your complimentary ticket at NZ
                    </Paragraph>
                </ContentBlock>
            </section>
        </ScrollToTop>
    );
};
