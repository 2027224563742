import React from 'react';
import { Helmet } from 'react-helmet';
import { ScrollToTop } from '../../../common/scripts/scroll-to-top';
import { isMobileOnly } from 'react-device-detect';
import { isPortraitTablet } from '../../../app';
import { BackLink } from '../../../common/elements/back-link/back-link';
import { PictureBlock } from '../../../common/elements/picture-block/picture-block';
import { ContentBlock } from '../../../common/elements/content-block/content-block';
import { Title } from '../../../common/elements/title/title';
import { ExternalLink } from '../../../common/elements/external-link/external-link';

import css from './../info.module.scss';

export const OurHouseRules = () => {
    return (
        <ScrollToTop>
            <Helmet>
                <title>House rules | Nieuwezijds Gay Sauna Amsterdam</title>
                <meta
                    name="description"
                    content="The house rules of the Nieuwezijds Gay Sauna in Amsterdam."
                />
            </Helmet>
            <section className={css.root}>
                <PictureBlock />
                <ContentBlock position="2">
                    {(isMobileOnly || isPortraitTablet) && (
                        <BackLink href="/info" text="info" />
                    )}
                    <Title as="h1" text="Our House Rules" />

                    <ul className={css.list}>
                        <li className={` ${css.listItem} ${css.underlined}`}>
                            entrance from 18 years
                        </li>
                        <li className={` ${css.listItem} ${css.underlined}`}>
                            ghb & other hard drugs strictly forbidden
                        </li>
                        <li className={` ${css.listItem} ${css.underlined}`}>
                            visitors under the influence of drugs or alcohol may
                            be refused entry or asked to leave
                        </li>
                        <li className={` ${css.listItem} ${css.underlined}`}>
                            if we suspect drug use we may ask you to open your
                            locker
                        </li>
                        <li className={` ${css.listItem} ${css.underlined}`}>
                            no prostitution
                        </li>
                        <li className={` ${css.listItem} ${css.underlined}`}>
                            discriminatory language, actions or symbols,
                            including tattoos, will not be tolerated
                        </li>
                        <li className={` ${css.listItem} ${css.underlined}`}>
                            it is forbidden to bring your own food or drinks to
                            the venue
                        </li>
                        <li className={` ${css.listItem} ${css.underlined}`}>
                            open bottles of poppers may be refused
                        </li>
                        <li className={` ${css.listItem} ${css.underlined}`}>
                            no caps, underwear or swimwear (swimwear allowed
                            thursday evening)
                        </li>
                        <li className={` ${css.listItem} ${css.underlined}`}>
                            no sleeping in cabines or other areas
                        </li>
                        <li className={` ${css.listItem} ${css.underlined}`}>
                            no photos are allowed to be taken anywhere in NZ,
                            and no phones allowed beyond lounge or locker area
                        </li>
                        <li className={` ${css.listItem} ${css.underlined}`}>
                            although we take the utmost care we are not liable
                            for any loss or damage of any kind resulting from
                            your visit to sauna nieuwezijds
                        </li>
                        <li className={` ${css.listItem} ${css.underlined}`}>
                            complaints & suggestions via{' '}
                            <ExternalLink
                                href="mailto:manager@saunanieuwezijds.nl"
                                text="manager@saunanieuwezijds.nl"
                            />
                        </li>
                    </ul>
                </ContentBlock>
            </section>
        </ScrollToTop>
    );
};
